<template>
  <div>
    <hero-alt
      img="https://image.freepik.com/photos-gratuite/chalkboard-points-interrogation-main-craie_1205-1001.jpg"
      title="Foire Aux Questions"
      subtext=""
    />
    <faq
      :data="faq"/>
  </div>
</template>

<script>
import api from "@/plugins/api";

export default {
  name: "Support",

  metaInfo: { title: "Help center" },

  methods : {
    referenceSelected(ref) {
      console.log(ref)
      this.reference = ref;
    }
  },
  data: () => ({
    faq: []
  }),

  async mounted() {
    this.faq = await api.help.getAll('fr');
  },

  components: {
    HeroAlt: () => import("@/components/mixins/HeroAlt"),
    Faq: () => import("@/components/Faq")
  }
};
</script>
